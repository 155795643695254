import React from "react";
import { Container, Stack, Text, Button } from "basis";
import styled from "styled-components";
import { BREAKPOINT, COLOR } from "../../../../../components/theme";
import { Icon } from "../../../../../components";
import { orderStatusPOS } from "../../../utils/index";

import { MERCHANT_PAGE_ROUTES, TRANSACTION_OUTCOME } from "../../../../../core/constants";
import { redirect } from "../../../../../core/navigate";

const Pending = ({ order, onManualPaymentClick, dashboard = false }) => {
  const status = orderStatusPOS(order?.type, order?.status);

  const statusText =
    status === TRANSACTION_OUTCOME.APPROVED ||
      status === TRANSACTION_OUTCOME.AUTHORISED
      ? "authorised"
      : status === TRANSACTION_OUTCOME.DECLINED
        ? "declined"
        : "pending";

  const StyledButtonContainer = styled.div`
    border-radius: 10px;
    background-color: ${COLOR.WHITE};
    padding: 05px 0 0 0;
    text-align: center;
    @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
      padding: 05px 0 0 0;
    }

    button {
      min-width: 215px;
    }
  `;
  const returnToDashboard = () => {
    redirect(MERCHANT_PAGE_ROUTES.OrderDashboard);
  };
  return (
    <>
      <Stack gap="8" align="center" margin="14 0">
        {status === "Quote" ? (
          <Icon type="awaitingPayment" size="172px" />
        ) : status === TRANSACTION_OUTCOME.APPROVED ||
          status === TRANSACTION_OUTCOME.AUTHORISED ? (
          <Icon type="authorised" size="172px" />
        ) : (
          <Icon type="declined" size="172px" />
        )}
        <Container>
          {status === "Quote" ? (
            <>
              <Text align="center">Awaiting customer payment</Text>
              <Text align="center">
                Latitude transaction ref:{" "}
                {order?.transactionReference || "Awaiting payment"}
              </Text>

              <StyledButtonContainer>
                <Button onClick={(e) => onManualPaymentClick(e)}>
                  Manual Payment
                </Button>
                {dashboard && (<>
                  <Stack>
                    <Text align="center" margin="4 0" color="grey.t75">
                      <strong>OR</strong>
                    </Text>
                    <Button onClick={returnToDashboard}>
                      Return to dashboard
                    </Button>
                  </Stack>
                </>
                )}
              </StyledButtonContainer>

            </>
          ) : (
            <>
              <Text align="center">Customer payment {statusText}</Text>
              <Text align="center">
                Latitude transaction ref:{" "}
                {order?.transactionReference ?? "pending"}
              </Text>
              <Text align="center" margin="3 0 0">
                The transaction is completed, please close the window
              </Text>
              {dashboard && (
                <StyledButtonContainer>
                  <Button onClick={returnToDashboard}>
                    Return to dashboard
                  </Button>
                </StyledButtonContainer>
              )}
            </>
          )}
        </Container>
      </Stack>
    </>
  );
};

export default Pending;
